import {
    required,
    requiredIf
} from 'vuelidate/lib/validators';

export const validationRules = {
    address: {
        required
    },
    city: {
        required
    },
    zip: {
        required
    },
    country: {
        required
    },
    region: {
        required: requiredIf(function () {
            return this.regionsLength > 0
        })
    }
};
